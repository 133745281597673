import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  {
    path: 'link-settings/track',
    loadChildren: () => import('./tracking/tracking.module').then((m) => m.TrackingModule)
  },
  {
    path: 'link-settings/track-review',
    loadChildren: () => import('./tracking/tracking.module').then((m) => m.TrackingModule),
    data: {
      isTrackReviewToken: true
    }
  },
  {
    path: 'link-settings/track-review/review',
    loadChildren: () => import('./review/review.module').then((m) => m.ReviewModule),
    data: {
      isTrackReviewToken: true
    }
  },
  {
    path: 'link-settings/review',
    loadChildren: () => import('./review/review.module').then((m) => m.ReviewModule)
  },
  {
    path: 'link-settings/confirm',
    loadChildren: () => import('./confirm/confirm.module').then((m) => m.ConfirmModule)
  },
  {
    path: 'link-settings/program',
    loadChildren: () => import('./program/program.module').then((m) => m.ProgramModule)
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: '/error',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
