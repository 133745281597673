import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  error: string;

  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    this.error = navigation.extras.state?.error || 'error.linkError';
  }
}
