<div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.xs="center start" style="min-height: 100vh">
  <mat-card appearance="outlined" fxLayout="column" fxFlex.gt-xs="400px" fxFlex.xs="grow" ngClass.xs="xs">
    <mat-card-content fxFlex="grow" fxLayout="column" fxLayoutAlign="center center">
      <h3>{{ 'common.error' | translate }}</h3>
      <img src="assets/images/error.svg" height="100px" />

      <p>
        {{ error | translate }}
      </p>
    </mat-card-content>
  </mat-card>
</div>
